.promo {
    flex: 4;
  
    display: flex;
  
    
  }
  
  .title-promo {
    display: flex;
  
    justify-content: center;
  
    align-items: center;
  
    
  }
  
  .button {
    border: none;
  
    background-color: red;
  
    color: white;
  
    cursor: pointer;
  }
  
  .desc {
   
  
    width: 30%;
  }
  
  .promo-container img{
  
      margin-right: 30px;
  }
  .create-promo {
    display: flex;
  
    flex-direction: column;
  
    justify-content: center;
  
    align-items: center;
  
    cursor: pointer;
  }
  
  .title-input {
    
    padding: 10px;
    width: 100%;
  }
  
  .desc-input {
    padding: 40px;
  
    
  }
  
  .upload-button {
    border: none;
  
    background-color: teal;
  
    color: white;
  
    padding: 10px;
  }
  .promo-detail{
  
      flex: 1;
  
  }
  
  .create-blog{
    flex: 2;
  }


 .blog-input{
  padding: 10px;
  width:90%;
 }
 .metadescription{

 
  padding: 40px;
  width: 100%;
 }
 .blog-desc{
  padding: 50px;
  height: 500px;
  width: 100%;
 }
 .blog-container{
  display: flex;
  flex-direction: column;
 }