.productList{
    flex: 4;
   
}


.productListItem{
    display: flex;
    align-items: center;
}

.productListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

.productListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
    width: 70px;
}

.productListDelete{
    color: red;
    cursor: pointer;
}

.pop{

    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
}

.pop-item{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30% 10%;
}

.pop-over{

    height: 100%;
    width: 30%;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;

}

.pop-item span{

    font-size: 20px;
    margin: 20px;
    font-weight: 900;
}
.buttons{

    display: flex;
    margin: 20px;
    font-weight: 900;
}
.buttons button:first-child{
    
    background-color: gray;
    border: none;
    font-weight: 900;
    width: 150px;
    padding: 10px;
    cursor: pointer;
}

.buttons button:last-child{
    background-color: red;
    border: none;
    cursor: pointer;
    font-weight: 900;
    width: 150px;
    padding: 10px;
}